import { Autocomplete, Box, FormHelperText, FormControl, MenuItem, TextField } from '@mui/material';
import { useFormContext, useController, RegisterOptions } from 'react-hook-form';
import { ReactNode, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircle from 'component/new_design/icons/CheckCircle.svg?react';
import { Label } from './Label';

export type Option<T> = {
  readonly value: T;
  readonly label: ReactNode;
};

export default function AutomcompleteField<T extends string | number>({
  name,
  options,
  isLoading,
  label,
  placeholder,
  rules,
  onInputValueChange,
}: {
  readonly name: string;
  readonly options: Option<T>[];
  readonly isLoading?: boolean;
  readonly label?: string;
  readonly placeholder?: string;
  readonly rules?: RegisterOptions;
  readonly onInputValueChange: (value: string) => void;
}) {
  const formContext = useFormContext();
  const id = useId();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const value = formContext.watch(name);
  console.log(value, typeof value);

  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    rules,
  });

  let hasError = !!error?.message;
  let errorMsg = String(error?.message);

  if (error?.type === 'required' && !hasError) {
    hasError = true;
    errorMsg = t('field_required');
  }

  return (
    <FormControl>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <Autocomplete
        onChange={(_event: any, newValue: Option<T> | null) => {
          const value = newValue?.value;
          if (value) {
            formContext.setValue(name, Number(value));
          } else {
            formContext.setValue(name, null);
          }
        }}
        renderOption={(props, option) => {
          // eslint-disable-next-line react/prop-types
          const { key, ...optionProps } = props;
          return (
            <MenuItem key={option.value} value={option.value} {...optionProps}>
              {option.label}
              {option.value === value ? (
                <Box sx={{ ml: 'auto', display: 'grid', placeItems: 'center' }}>
                  <CheckCircle />
                </Box>
              ) : null}
            </MenuItem>
          );
        }}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          onInputValueChange(newInputValue);
          setInputValue(newInputValue);
        }}
        id={id}
        options={options}
        disabled={isLoading || isSubmitting}
        renderInput={params => (
          <TextField
            variant="filled"
            {...field}
            {...params}
            fullWidth
            placeholder={placeholder}
            size="small"
            error={Boolean(formContext.formState.errors[name])}
          />
        )}
      />
      {hasError ? <FormHelperText error>{errorMsg}</FormHelperText> : null}
    </FormControl>
  );
}
